@import "../../../variable.less";

.modal_headerText {
  font-size: 14px;
  font-weight: 400;
  color: @text-color;
}

.stepFour_finalStepHeader {
  /* background: yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
  .stepFour_headerText {
    /* border: 1px solid black; */
    color: green;
    font-size: 22px;
    font-weight: bold;
    margin-left: 20px;
    @media screen and (max-width: 435px) {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 435px) {
    flex-direction: column;
  }
}

