@import "../../../variable.less";

.welcomePage_cardWrapper {
  width: 100%;
  max-width: 550px;
  margin: 30px auto;

  .welcomePage_card {
    // background: blue;

    .avatar_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .welcomePage_avatar {
        border-radius: 50%;
      }
    }

    .divider {
      border: 1px solid @border;
      max-width: 352px;
      width: 100%;
      margin: 24px auto;
    }

    .welcomePage_textWrapper {
      //   background: @bg;

      .bold_text {
        // color: blue;
        font-size: 18px;
        font-weight: bold;
      }

      .normal_text {
        font-size: 14px;
        font-weight: 400;
        color: @text-color;
        line-height: 1.5;

        :global(.ant-btn-link) {
          padding: 0 3px;
        }
      }
    }

    .welcomePage_btnWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;

      .startBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        background: @primary;
        height: unset;
        padding-block: 8px;

        > div {
          flex-wrap: wrap;
        }
      }

      .upgradeBtn {
        :global(.ant-typography) {
          color: @primary;
          font-size: 16px;
          font-weight: 400;
        }
      }

      .btn_boldText {
        color: @white;
        font-size: 16px;
        font-weight: bold;
      }

      .btn_smallText {
        color: @white;
        font-size: 12px;
      }
    }

    :global(.ant-card-body) {
      padding: 30px 55px;
    }
  }
}

.setupVideo {
  margin-top: 2rem;
  border: 1px solid grey;
  max-width: 500px;
  display: block;
  margin-inline: auto;
  aspect-ratio: 853 / 480;
  width: 100%;
}
