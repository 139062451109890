@import "~antd/dist/antd.less";
@import "./variable.less";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.page__wrapper {
  width: 100%;
  height: 100vh;
  // background: @bg;
}

.ant-card {
  border: @card-border;
}

// incres checkbox size

.ant-checkbox-inner,
.ant-checkbox-input {
  transform: scale(1.5);
}

// settingpage navbar css

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.trigger svg {
  height: 30px;
  /* color: white; */
  width: 30px;
  margin-left: 5px;
}


// change card payment component input placeholder

// .placeholder(@color) {
//   ::-webkit-input-placeholder {color:@color }
//   ::-moz-placeholder {color:@color}
//   :-ms-input-placeholder {color:@color}

// }

// #name_on_card{
//   .placeholder( #706d6d;);
// }

.ant-image-preview-operations-operation {
  &:last-child, &:nth-last-child(2) {
    display: none;
  }
}

