.settingPage_colum {
  text-align: center;

  .settingElement_card {
    cursor: pointer;
    border: none;
    .settingCard_img {
      height: 40px;
      width: 40px;
    }

    :global(.ant-card-body) {
      text-align: center;
      padding: 12px;
    }
  }
}

.disabledLink {
  opacity: 0.8;
  display: inline-block;

  &, & * {
    cursor: not-allowed;
  }
}
