@import "../../../variable.less";

.settingPage_inner_content {
  width: 100vw;
  max-width: 53vw;
  margin: 0 auto;
  /* background: pink; */

  .enabled_app_card {
    width: 60%;
    margin: 0 auto;
    margin-top: 40px;

    .enabled_card_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    :global(.ant-card-body) {
      padding: 12px;
    }
  }

  .settingPge_divider {
    border: 1px solid @border;
  }

  @media screen and (max-width: 1108px) {
    .enabled_app_card {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 650px) {
    .enabled_card_content {
      flex-direction: column;
      align-items: unset !important;
    }
    .enabled_switch {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 532px) {
    :global(.ant-space) {
      display: inline-block;
    }
    :global(.ant-tag) {
      width: 100%;
      text-align: center;
    }
  }
}

//   media queries

