@import "../../../variable.less";
.stepFour_BodyWrapper {
  /* border: 1px solid black; */
  .stepFour_boldText {
    /* background: green; */
    padding: 15px 20px;
    font-size: 19px;
    font-weight: bold;
    // text-align: center;

    // @media screen and (max-width: 805px) {
    //   padding: 15px 20px;
    // }
  }

  .stepFour_cardWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    .stepFour_btn {
      padding: 5px 49px;
      height: 40px;
      font-size: 16px;
      font-weight: 500;
      margin-top: 20px;
      background: @primary;
    }
  }
}

