@import "../../../variable.less";
.footerWrappper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 12px;
  .nextBtn {
    border-radius: 3px;
    i {
      margin-left: 5px;
    }
  }

  .backBtn {
    border-radius: 3px;
  }

  // .footer_rightSideContent {
  //   .footer_helpBtn {
  //     text-align: right;
  //     color: @primary;
  //     font-size: 16px;
  //     cursor: pointer;
  //   }
  //   .footer_text {
  //     font-size: 16px;
  //   }

  //   @media screen and (max-width: 468px) {
  //     margin-top: 10px;
  //     .footer_helpBtn {
  //       text-align: left;
  //     }
  //   }
  // }
}

.footerWrappper_for_finalStep {
  padding: 20px 0;
}

