@import "../../../../variable.less";

.stepFour_wrapper {
  .stepFour_boldText {
    font-size: 16px;
    font-weight: bold;
    color: @text-color;
  }
  .stepFour_smallText {
    font-size: 13px;
    font-weight: 400;
    color: @text-color;
  }
  .stepFour_switch {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media screen and (max-width: 427px) {
      display: block;
    }
  }
  @media screen and (max-width: 575px) {
    .currencyFormat_selectBox {
      margin-top: 10px;
    }
  }
}

