@import '~antd/dist/antd.variable.less';

.upgradePlanPage_wrapper {
  .upgradePlanPage_content {
    //width: 100%;
    //max-width: 600px;
    //min-width: 600px;
    padding-bottom: 35px;
    margin: 35px auto;

    // border: 1px solid black;

    .upgradePlanPage_heading{
      display: flex;
      align-items: center;
      justify-content: center;
      .backIcon{
        width: 50px;
        height: 50px;
        margin-right: 20px;
        cursor: pointer;
      }
    }

    .upgradePlanPage_heading_text {
      display: block;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #000;
    }

    .upgradePlanPage_infoCard_wrapper {
      margin-top: 20px;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;

      @media (max-width: @screen-xs) {
        flex-direction: column-reverse;
      }
    }
  }
}

.planSwitchContainer {
  margin-block: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offText {
  display: inline-block;
  font-weight: 500;
  background: #3dc132;
  color: white;
  font-size: 1rem;
  padding: 0.2rem 1rem;
  width: 65%;
  margin-inline: 17.5%;
  margin-top: 1rem;
  text-align: center;
  border-radius: 5px;

  span {
    font-weight: 600;
    font-style: italic;
  }
}

.planFeatureContainer {
  display: inline-block;

  span {
    vertical-align: middle;
  }

  img {
    width: 1.4em;
    height: 1.4em;
  }
}

