.logoSideBar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  height: 85px;
}

.logoText{
  font-size: 16px;
  font-weight: 600;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  // background: yellow;
  height: 100%;
}

@media screen and (max-width: 1000px) {
  .logoText {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .logoText {
    display: block;
    font-size: 16px;
  }
}

