@import "../../../variable.less";

.modal_component {
  :global(.ant-modal-header) {
    padding: 15px 50px;
    border-radius: 5px;
  }
  :global(.ant-modal-content) {
    border-radius: 5px;
  }
  :global(.ant-modal-body) {
    padding: 15px 50px;
  }

  :global(.ant-modal-footer) {
    text-align: left;
    padding: 5px 50px;
    background: #f0f0f0;
    border-radius: 5px;
  }

  @media screen and (max-width: 466px) {
    :global(.ant-modal-header) {
      padding: 15px 24px;
    }
    :global(.ant-modal-footer) {
      padding: 15px 24px;
    }
    :global(.ant-modal-body) {
      padding: 15px 24px;
    }
  }
}

