@import "../../../variable.less";

.container {
  display: inline-block;
  width: 250px;
  border: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  user-select: none;
}

.radioGroupContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  border: 1px solid rgba(57, 76, 96, .15);
  border-radius: 8px;
  height: 56px;
  align-items: center;
  background-color: #fff;
  min-width: max-content;
  user-select: none;
  white-space: nowrap;
}

.switchHighlighter {
  user-select: none;
  white-space: nowrap;
  align-self: stretch;
  grid-column: 1;
  grid-row: 1;
  //background-color: #7d2ae8;
  background-color: @primary;
  margin: 8px;
  border-radius: 8px;
}

.yearlySwitchHighlighter {
  transform: translateX(0);
  transition: transform .2s ease,-webkit-transform .2s ease;
}

.monthlySwitchHighlighter {
  transform: translateX(calc(100% + 16px));
  transition: transform .2s ease,-webkit-transform .2s ease;
}

.label {
  position: relative;
  grid-row: 1;
  text-align: center;
  margin: 0 16px;

  input {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    left: 0;
    top: 0;
  }

  p {
    user-select: none;
    white-space: nowrap;
    text-align: center;
    overflow-wrap: break-word;
    font-weight: 400;
    line-height: 1.6;
    margin: 8px 0;
    display: inline-block;
    border-radius: 8px;
    padding: 8px 0;
    transition: color .2s ease;
  }

  input:checked + p {
    color: #fff;
  }
}

.yearlyLabel {
  grid-column: 1;
}

.monthlyLabel {
  grid-column: 2;
}

.switch {
  white-space: nowrap;
  border: none;
  background: none;
  color: inherit;
  font: inherit;
  padding: 0;
  margin: 0;
  user-select: none;
  outline: none;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

