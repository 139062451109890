.dashboardPage_wrapper {
  //   background: yellowgreen;
  width: 100%;
  height: 80%;

  .dashboardPage_card_wrapper {
    //width: 50%;
    margin: 40px auto 50px;
    max-width: 550px;
    width: 100vw;

    .dashboardPage_card {
      .heading_wrapper {
        text-align: center;
        .heading {
          font-size: 22px;
        }
      }

      .normal_text {
        font-size: 14px;
        font-weight: 400;
        // margin-bottom: 50px;
      }

      .bold_text {
        margin-right: 5px;
        font-size: 16px;
      }

      .linkBtn {
        padding: 0 1px;
        height: inherit;
      }

      :global(.ant-card-body) {
        padding: 35px 55px;
      }
    }
  }
}

.setupVideo {
  border: 1px solid grey;
  max-width: 500px;
  display: block;
  margin-inline: auto;
  aspect-ratio: 853 / 480;
  width: 100%;
}
