@import "../../../../variable.less";

.stepOne_CurrenciesHeader {
  margin: 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .title_text {
      font-size: 16px;
      font-weight: 600;
    }

    .header_btn {
      padding: 3px;
    }

    @media screen and (max-width: 411px) {
      align-items: flex-start;
      flex-direction: column;
      .header_btn {
        padding: 0;
      }
    }
  }
}
.stepOne_text {
  font-size: 14px;
  font-weight: 400;
  color: @text-color;
}

.stepOne_currenciesSelectBox {
  margin-top: 10px;
  
  :global(.ant-select-selector) {
    overflow: auto;
    max-height: 100px;
  }
}

.pricingPageLinkBtn {
  padding: 0;
  //height: 1rem;
}

.supportedCurrenciesFormItem :global(.ant-form-item-control){
  flex-direction: column-reverse !important;
}

.btn_wrapper {
  @media screen and (max-width: 411px) {
    margin-top: .5rem;
  }
}

.tooltipIcon {
  color: #d0d0d0;
  margin-left: 6px;
  font-size: 1.2rem;
  vertical-align: middle;
}

