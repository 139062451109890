.helpPage_wrapper {
  width: 100%;
  height: 90%;

  .helpPage_content {
    width: 100%;
    margin: 50px auto;
    max-width: 550px;
    padding: 5px 20px;

    .normal_text {
      font-size: 14px;
    }

    .helpPage_heading {
      .boldText {
        font-size: 16px;
      }
    }

    .helpPage_card {
      .helpPage_avatar_wrapper {
        text-align: center;
        .card_heading {
          font-size: 22px;
          font-weight: bold;
        }
        .helpPage_avatar {
          border-radius: 50%;
        }
      }

      .card_text_wrapper {
        margin-top: 15px;
      }

      :global(.ant-card-body) {
        padding: 24px 63px;
      }
    }

    .linkBtn {
      padding: 0 3px;
      height: inherit;
    }
  }
}

