@import "../../../../variable.less";

.stepThree_wrapper {
  margin-top: 15px;
  .color_demo {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 0.2px solid @border;
  }

  .stepThree_advancedLinkBtn {
    padding: 0;
    margin-bottom: 15px;
  }

  .stepThree_text {
    margin-left: 10px;
  }

  .stepThree_showFlagContainer{
    // border: 1px solid black;
    display: flex;
    align-items: baseline;
    // justify-content: center;
  }

 
}

