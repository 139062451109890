.modalBody_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}
.modalBody_wrapper .modalBody_leftSide {
  width: 60%;
}
.modalBody_wrapper .modalBody_rightSide {
  width: 40%;
  margin-top: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 650px) {
  .modalBody_wrapper {
    flex-direction: column;
  }
  .modalBody_wrapper .modalBody_leftSide {
    width: 100%;
    order: 2;
  }
  .modalBody_wrapper .modalBody_rightSide {
    margin-bottom: 24px;
    width: 100%;
    order: 1;
  }
}
