.hide {
  display: none;
}

.keyframes(@name; @arguments) {
  @-moz-keyframes @name {
    @arguments();
  }
  @-webkit-keyframes @name {
    @arguments();
  }
  @keyframes @name {
    @arguments();
  }
}

.animation(@arguments) {
  -webkit-animation: @arguments;
  -moz-animation: @arguments;
  animation: @arguments;
}

.upgradePlanPage_infoCard {
  :global(.ant-card) {
    min-width: 300px;
  }
  :global(.ant-card-body) {
    padding: 0;
  }
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  width: 100%;

  .infoCard_recommended_section {
    width: 100%;
    background: #000;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 5px;
    text-align: center;
  }

  .infoCard_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px 0;

    .infoCard_title {
      font-size: 20px;
      font-weight: 400;
    }

    .infoCard_price {
      .original_price,
      .current_price {
        font-size: 35px;
        font-weight: 500;
        color: #000;
        margin-left: 10px;
      }

      .month_text {
        font-size: 20px;
        font-weight: 400;
        color: #000;
      }
    }

    .freeTrial_day,
    .paid_annually {
      font-size: 16px;
      font-weight: 400;
      margin-top: 5px;
      color: #00a2ff;
    }

    .paid_annually {
      margin-top: 0;
    }

    .infoCard_install_btn {
      margin-top: 30px;

      .animatedBtn {
        min-width: 120px;
        background: #0dbf40;
        //background: #000;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        border: none;

        .keyframes(glowing ;{
                    0% {
                      box-shadow:  0 0 6px 6px #075b1d;
                      //box-shadow:  0 0 6px 6px #474646;
                    }
                    25% {
                      box-shadow: 0 0 6px 6px #097926;
                    }
                    50% {
                      box-shadow:  0 0 6px 6px #0b9a31;
                    }
                    75% {
                      box-shadow: 0 0 6px 6px #0bab39;
                    }
                    100% {
                      box-shadow: 0 0 6px 6px #0dbf40;
                    }
                  });

        .animation(glowing  1s cubic-bezier(0.66, 0, 0, 1) infinite);
      }

      .normalBtn {
        width: 135px;
        outline: none;
        border: 1px solid black;
        font-size: 16px;
        font-weight: 600;
        color: #000;
      }
    }

    .infoCard_customers_used_details {
      margin-top: 25px;
      background: #d0d0d0;
      color: #000;
      text-align: center;
      padding: 10px;
      width: calc(100% - 20px);
      font-size: 14px;
      font-weight: 400;
      border: 1px solid black;
      box-sizing: content-box;

      span {
        font-weight: bold;
      }
    }

    .infoCard_plan_desc {
      padding: 20px;
    }
  }
}

