.cardPayment_wrapper {
  margin-top: 10px;
  height: 80%;
  :global(.ant-card-body) {
    padding: 0px;
  }

  .cardPayment_form_wrappper {
    .cardPayment_fields_wrapper {
      padding: 30px 45px;
      .cardPayment_cardDetails {
        margin-top: 30px;
      }

      .cardPayment_form_label {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
        display: block;
      }
    }

    .cardPayment_wrapper {
      padding: 45px;
      padding-top: 0;
    }

    .cardPayment_btnWrapper {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .addCard_btn{
        margin-left: 20px;
      }
      

      :global(.ant-btn) {
        font-size: 16px;
        font-weight: 600;

      }

      @media screen and (max-width: 386px) {
     
        flex-direction: column;
        .addCard_btn{
          margin-left: 0px;
          order: 1;
        }
        .cancel_btn{
          order: 2;
          margin-top: 20px;
        }
       
      }

    }
  }
}

.cardDetails_wrapper{
  margin-top: 50px;
 
  .cardDetails{
    padding: 20px;
  }
}

