@import "../../../variable.less";
@import "../Onboarding.module.less";

.modalBody_leftSide {
  margin-left: 80px;
  margin-top: 30px;

  @media screen and (max-width: 650px) {
    padding: 10px 30px;
    margin-left: 0;
  }
  // 411
  @media screen and (max-width: 411px) {
    padding-left: 0;
  }
}

