@import "../../../variable.less";

.preview_wrapper {
  margin-top: 80px;
  .preview {
    border: 1px solid @border;
    width: 90px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 80px;
  }
  :global(.ant-space) {
    text-align: center;
  }

  @media screen and (max-width: 650px) {
    margin-top: 5px;
  }
}

