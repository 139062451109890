@import "../../../../variable.less";

.stepTwo_boldText {
  font-size: 16px;
  font-weight: 600;
  color: @text-color;
}

.stepTwo_smallText {
  font-size: 14px;
  font-weight: 400;
  color: @text-color;
}

.donnotShow_radioBtn {
  margin-top: 35px;
}
// ***************

.stepTwo_fixPosition_content {
  margin-top: 15px;
  margin-bottom: 15px;
  .left_colum {
    text-align: right;
  }
  .right_colum {
    text-align: left;
  }

  .center {
    position: relative;
    width: 100%;
    height: 100%;

    .laptopBackground {
      height: 100%;
      width: 100%;
    }

    .top_left_radioBtn {
      position: absolute;
      top: 0px;
      left: 0px;
    }

    .bottom_left_radioBtn {
      position: absolute;
      top: 60px;
      left: 0px;
    }

    .top_right_radioBtn {
      position: absolute;
      top: 0px;
      right: 0px;
    }

    .bottom_right_radioBtn {
      position: absolute;
      top: 60px;
      right: 0px;
    }

    .fixPosition_radioBtn_Wrapper {
      position: absolute;
      left: 0;
      right: 0;
      width: 67%;
      margin: 0 auto;
      top: 12px;
      height: 75%;

      :global(.ant-radio-wrapper) {
        margin-right: 0;
      }
    }
  }

  .stepTwo_advancedCustomization_wrapper {
    .stepTwo_advancedLinkBtn {
      padding: 0;
      margin-top: 20px;
      margin-left: 25px;
    }

    .advancedCustomization_element {
      margin-top: 10px;
      margin-left: 25px;
    }
  }
}

