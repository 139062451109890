@import "../../../variable.less";

.sidebar_notcollapsed {
  background: white;

  flex: 0 0 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;
  width: 200px !important;

  border-right: 1px solid @border;
}

.sidebar_notcollapsed,
.sidebar_collapsed {
  z-index: 201;
}

.sidebar_collapsed {
  width: 0;
  background: white;
  flex: 0 0 0px !important;
  max-width: 0px !important;
  min-width: 0px !important;
  width: 0px !important;
}

.settingPage_wrapper {
  width: 100%;
  height: 100vh;

  .settingPage_header_responsive {
    background: white;
    display: none;
  }

  .settingPage_header {
    background: white;
    /* display: none; */
    border-bottom: 1px solid @border;

    // .settingPage_menuColum{
    //   border: 1px solid black;
    // }

    .settingPage_col_border_bouttom {
      border-bottom: 1px solid @border;
    }
  }

  //   media queries

  @media screen and (max-width: 768px) {
    .settingPage_header {
      display: none;
    }
    .sidebar_notcollapsed .sidebar_collapsed {
      display: block;
    }
    .settingPage_header_responsive {
      display: block;
    }
  }
}

.drawerMenu {
  border-right: none;
}

